import React from 'react'
import Session from "./Session";
import styled from 'styled-components';


const ColumnContainer = styled.div`
    width: 100%;
`

function ParallelSessions({namesAndLocations, track, sessions, rooms}){
    const sessionsComponent = track.sessions.map((s) => {
        const fullName = namesAndLocations[s]?.name
        const place = rooms[namesAndLocations[s].localization]?.name
        const placeUrl = rooms[namesAndLocations[s].localization]?.url
        const chairman = namesAndLocations[s]?.chairman
        return (<div className="column" key={`s-${s}`}><Session loc={place} locUrl={placeUrl} chairman={chairman} name={fullName} session={sessions[s]} /></div>)
    })

    return (
        <ColumnContainer className="columns" >
            {sessionsComponent}
        </ColumnContainer>
    );
}

export default ParallelSessions
