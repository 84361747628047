import React from 'react'
import styled from "styled-components";
import logo from '../Assets/header_logo.png'
import topBg from '../Assets/top_bg.png';

const ConfTitle = styled.h1`
  font-size: 1.9em;
  font-weight: 300;
  padding-bottom: 5px;
    font-stretch: semi-condensed;
    font-family: "Libre Franklin", sans-serif;
`

function Header(props){
    return (
        <div className="panel-heading">
            <div className="columns">
                <div className="column" style={{display: "flex", alignItems: "center", backgroundRepeat: 'repeat-x', backgroundPosition: 'center', backgroundSize: 'contain', backgroundImage: `url(${topBg})`, paddingLeft: 0 }}>
                    <img src={logo} alt="Metal Forming 2024 Logo" style={{width: 300, marginRight: 20}}/>
                    <ConfTitle className="is-hidden-mobile">Programme</ConfTitle>
                </div>
            </div>
        </div>
    )
}

export default Header
