import React from 'react';
import './App.css'
import "bulma/css/bulma.min.css";
import 'bulma-switch-control/css/main.min.css';
import 'react-toastify/dist/ReactToastify.css';
import withSplashScreen from "./Components/withSplashScreen";
import Home from "./Components/Home";
import {BrowserRouter as Router, Route, Routes} from "react-router-dom";
import {CookiesProvider} from "react-cookie";
import {ToastContainer} from "react-toastify";

function App() {

    return (
        <CookiesProvider>
            <div className="App">
                <Router>
                    <Routes>
                        <Route path='/' element={<Home/>}/>
                    </Routes>
                </Router>
                <ToastContainer />
            </div>
        </CookiesProvider>
    );
}

export default withSplashScreen(App);
