import EuLogo from "../Assets/logo_eu.png";
import EuFunds from "../Assets/logo_ef.png";
import Doskonala from "../Assets/logo_doskonala.png";
import NcbrLogo from "../Assets/logo_ncbr.png";
import AghLogo from "../Assets/logo_agh.png";
import MenLogo from "../Assets/logo_ministry.png";
import KrakowLogo from "../Assets/logo_krakow.png";

import styled from 'styled-components'

const FooterContainer = styled.footer`
  padding: 2rem 1.5rem 2rem;
`

const Footer = () => {
    return (
        <FooterContainer className="footer">

            <div className="container">
                {/*<div className="has-text-centered p-4">*/}
                {/*    <p>*/}
                {/*        The activity is co-financed from the state budget under the programme of the Minister of Education and Science called 'Excellent Science' project no. DNK/SP/514574/2021*/}
                {/*    </p>*/}
                {/*</div>*/}

                <div className="columns is-align-items-center p-4">

                    <div className="column has-text-centered">
                        <img src={MenLogo} alt="Minister of Science RP"/>
                    </div>

                    <div className="column has-text-centered">
                        <a href="https://agh.edu.pl">
                        <img src={AghLogo} alt="AGH University of Science"/></a>
                    </div>

                    <div className="column has-text-centered">
                        <a href="http://www.dlabiznesu.krakow.pl/nauka" target="_blank" rel="noreferrer">
                            <img src={KrakowLogo} alt="Krakow"/>
                        </a>
                    </div>

                </div>

                {/*<div className="columns is-align-items-baseline p-4">*/}

                {/*    <div className="column has-text-centered">*/}
                {/*        <a href="https://www.dlabiznesu.krakow.pl/nauka" target="_blank" rel="noreferrer">*/}
                {/*            <img src={KrakowLogo} alt="krakow"/>*/}
                {/*        </a>*/}
                {/*    </div>*/}

                {/*</div>*/}

                {/*<div className="has-text-centered p-4">*/}
                {/*    <p>*/}
                {/*This activity has received funding from the European Union from the European Regional Development Fund under the Intelligent Development Operational Program*/}
                {/*    </p>*/}
                {/*</div>*/}
                {/*<div className="columns is-align-items-baseline p-4">*/}
                {/*    <div className="column has-text-centered">*/}
                {/*        <img src={EuFunds} alt="European Funds"/>*/}
                {/*    </div>*/}

                {/*    <div className="column has-text-centered">*/}
                {/*        <img src={NcbrLogo} alt="National Centre for Research and Development"/>*/}
                {/*    </div>*/}

                {/*    <div className="column has-text-centered">*/}
                {/*        <img src={EuLogo} alt="European Union"/>*/}
                {/*    </div>*/}

                {/*</div>*/}

                <div className="has-text-centered p-4">
                    <p>
                        <a href="https://metalforming.agh.edu.pl/"> <strong>Metal Forming 2024</strong></a> - The 20-th
                        International Conference
                    </p>
                </div>
            </div>
        </FooterContainer>
    )
}

export default Footer
