import React, {useState} from 'react'
import SearchBar from "./SearchBar";
import ScheduleTabs from "./ScheduleTabs";
import Header from "./Header";
import sourceData from "../data"
import Footer from "./Footer";
import {AbstractView} from "./Abstract";
import {Message} from "./Message";
import {TimezoneSettings} from "./TimezoneSettings";
import {useAtom} from "jotai/index";
import {timezoneAtom} from "../Atoms/timezone";
import {findTimeZone, getUTCOffset} from "timezone-support";
import removeAccents from "remove-accents";

const resetHighlights = () => Array(5).fill(false)


const TzInfo = () => {
    const [tz] = useAtom(timezoneAtom);
    // console.log('tz', tz);

    const tzInfo = findTimeZone(tz);
    const date = new Date()
    // const opt = Intl.DateTimeFormat().resolvedOptions();
    // const tz = opt.timeZone;

    const offset = -getUTCOffset(date, tzInfo).offset / 60
    let offsetStr = offset > 0 ? `+${offset}` : offset.toString()
    return (
        <p>All presentation times on this page are in local Polish time zone: <strong>{tz} (UTC{offsetStr})</strong>.
        </p>
    )
}

const norm = (str) => removeAccents(str.toLowerCase());

function Home(props) {

    const [content, setContent] = useState(sourceData);
    const [searchError, setSearchError] = useState(false);
    const [highlightedDays, setHighlightedDays] = useState(resetHighlights())
    const [searchMode, setSearchMode] = useState(false)

    const onSearchChange = (searchText) => {
        if (searchText === '') {
            let newContent = Object.assign({}, sourceData)
            setContent(newContent)
            setSearchError(false)
            setSearchMode(false)
            setHighlightedDays([...resetHighlights()])
        } else if (searchText && searchText.length >= 3) {
            let newHighlights = resetHighlights()
            let newContent = Object.assign({}, sourceData)
            let found = 0

            const filtered = newContent.presentations.filter((p) => {
                let stay = false;
                const {title, authors, keywords} = p;
                const _title = norm(title);
                const _keywords = keywords.map(k => norm(k)).join(' ');
                const _authors = authors.map(a => norm(a)).join(' ');
                const _searchText = norm(searchText);

                if (_title.includes(_searchText)) {
                    stay = true;
                    found++;
                } else if (_keywords.includes(_searchText)) {
                    stay = true;
                    found++;
                } else if (_authors.includes(_searchText)) {
                    stay = true;
                    found++;
                }

                if (stay) {
                    const date = new Date(p.date);
                    const dayFromDate = date.getDay() - 1;
                    newHighlights[dayFromDate] = true;
                }
                return stay;
            })
            newContent.presentations = filtered;
            setContent(newContent);
            if (found === 0) setSearchError(true);
            else setSearchError(false);

            setSearchMode(true);
            setHighlightedDays(newHighlights);
        }
    }



    return (
        <div className="container">
                    <div className="panel">
                        <Header/>
                        <div>
                            <SearchBar onSearchChange={onSearchChange} searchError={searchError}/>
                            {/*<TimezoneSettings/>*/}
                        </div>

                        <Message content={<TzInfo/>} />
                        <ScheduleTabs data={content} highlightedDays={highlightedDays} searchMode={searchMode}/>

                        <Footer/>
                    </div>
            {/*<AbstractView/>*/}
        </div>
    )
}

export default Home
